@import 'mixins';

.content {
    padding: 0;

    .container {
        padding-top: 40px;
        padding-bottom: 40px;

        @include breakpoint('sm') {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        @include breakpoint('md') {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @include breakpoint('lg') {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    .main-slider {
        .slider__slide {
            img {
                min-height: 340px;
                max-height: 76vh;
                object-fit: cover;
            }

            a {
                width: 100%;
            }
        }

        .slider__arrow {
            display: none;

            @include breakpoint('md') {
                display: block;
            }

            &_prev {
                @include breakpoint('lg') {
                    left: 30px;
                }

                @include breakpoint('xl') {
                    left: 80px;
                }
            }

            &_next {
                @include breakpoint('lg') {
                    right: 30px;
                }

                @include breakpoint('xl') {
                    right: 80px;
                }
            }
        }

        .slider__dots {
            @include breakpoint('sm') {
                bottom: 20px;
            }
        }
    }

    .about-company {
        display: grid;

        @include breakpoint('lg') {
            grid-template-columns: minmax(417px, 1fr) minmax(500px, 1fr);
            align-content: start;
            align-items: center;
            column-gap: 50px;
        }

        &__heading {
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            .curve {
                display: none;
                margin-top: -20px;

                @include breakpoint('lg') {
                    display: block;
                }
            }
        }

        &__image {
            margin-bottom: 16px;

            @include breakpoint('lg') {
                grid-row: 1 / 4;
                grid-column: 2 / 3;
                margin: 0;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;

            @include breakpoint('md') {
                margin: 0;
            }

            .text {
                margin-bottom: 20px;
            }

            .button {
                margin-top: 10px;

                @include breakpoint('sm') {
                    align-self: flex-start;
                }
            }
        }
    }

    .catalog {
        margin-bottom: 40px;

        @include breakpoint('md') {
            margin-bottom: 60px;
        }

        @include breakpoint('lg') {
            margin-bottom: 100px;
        }

        &__slider {
            margin: 0 -20px 30px;

            @include breakpoint('sm') {
                margin: 0 -80px 30px;
            }

            @include breakpoint('md') {
                margin: 0 -100px 30px;
                padding: 0 100px;
            }

            @include breakpoint('lg') {
                display: none;
            }

            .swiper-container {
                padding: 0 20px;

                @include breakpoint('sm') {
                    padding: 0 80px;
                }

                @include breakpoint('md') {
                    padding: 0;
                }
            }

            .slider__slide {
                max-width: 250px;

                @include breakpoint('md') {
                    max-width: none;
                }
            }

            .slider__arrow {
                display: none;

                @include breakpoint('md') {
                    display: block;
                }

                &_prev {
                    left: 20px;
                }

                &_next {
                    right: 20px;
                }
            }
        }

        &__list {
            display: none;
            margin: -10px -10px 40px;
            flex-wrap: wrap;

            @include breakpoint('lg') {
                display: flex;
            }

            .catalog__list-item {
                margin: 10px;
                flex: 0 0 calc(100% / 2 - 20px);
            }
        }

        &__list-item {
            padding: 16px 16px 20px;
            border: 1px solid #e5e5e5;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            @include breakpoint('md') {
                padding: 20px;
            }

            @include breakpoint('lg') {
                border: none;
                box-shadow: 0 0 0 1px #e5e5e5;
                flex-direction: row;

                &:hover {
                    box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);

                    .info {
                        .button {
                            visibility: visible;
                        }
                    }
                }
            }

            .image {
                margin-bottom: 10px;

                @include breakpoint('lg') {
                    flex: 2 0 134px;
                    margin: 0 20px 0 0;
                }

                img {
                    height: 140px;
                    object-fit: contain;

                    @include breakpoint('md') {
                        height: 170px;
                    }

                    @include breakpoint('lg') {
                        height: 200px;
                    }
                }
            }

            .info {
                height: 100%;
                display: flex;
                flex-direction: column;

                @include breakpoint('lg') {
                    flex: 3 0 278px;
                }

                .title {
                    color: #005a70;
                    font-size: 20px;
                    line-height: 24px;
                    margin-bottom: 10px;

                    @include breakpoint('lg') {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }

                .description {
                    color: #6c787b;
                    @include line-clamp(5);
                    margin-bottom: 16px;
                }

                .button {
                    margin-top: auto;

                    @include breakpoint('lg') {
                        align-self: flex-start;
                        visibility: hidden;
                    }

                    &_secondary {
                        @include breakpoint('lg') {
                            display: none;
                        }
                    }

                    &_primary {
                        display: none;

                        @include breakpoint('lg') {
                            display: flex;
                        }
                    }
                }
            }
        }

        &__button {
            @include breakpoint('sm') {
                width: 300px;
                margin: 0 auto;
            }
        }
    }

    .brands {
        &__slider {
            margin: -30px -20px;

            @include breakpoint('sm') {
                margin: -30px -80px;
            }

            @include breakpoint('md') {
                margin: -30px -100px;
                padding: 0 88px;
            }

            @include breakpoint('lg') {
                display: none;
            }

            .swiper-container {
                padding: 30px 20px;

                @include breakpoint('sm') {
                    padding: 30px 80px;
                }

                @include breakpoint('md') {
                    padding: 30px 12px;
                }
            }

            .slider__slide {
                max-width: 220px;

                @include breakpoint('md') {
                    max-width: none;
                }
            }

            .brands__list-item {
                width: 100%;
            }

            .slider__arrow {
                display: none;

                @include breakpoint('md') {
                    display: block;
                }

                &_prev {
                    left: 20px;
                }

                &_next {
                    right: 20px;
                }
            }
        }

        &__list {
            display: none;
            margin: -10px;

            @include breakpoint('lg') {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &__list-item {
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0px 8px 30px rgba(108, 108, 108, 0.15);
            display: flex;
            justify-content: center;
            align-items: center;

            @include breakpoint('lg') {
                flex: 0 0 calc(100% / 4 - 20px);
                margin: 10px;
            }

            img {
                height: 60px;
                object-fit: contain;

                @include breakpoint('md') {
                    height: 80px;
                }
            }
        }
    }

    .contact-us {
        display: flex;
        flex-direction: column;

        @include breakpoint('md') {
            max-width: 640px;
            margin: 0 auto;
        }

        &__heading {
            margin-bottom: 20px;
        }

        &__form {
            flex-direction: row;
            flex-wrap: wrap;

            .form__inputs {
                flex: 0 0 calc(100% + 28px);

                @include breakpoint('md') {
                    flex: 0 0 calc(100% / 2 + 18px);
                    margin: -3px 6px 7px -14px;
                }
            }

            .textarea,
            .form__submit {
                flex: 0 0 100%;

                @include breakpoint('md') {
                    flex: 0 0 calc(100% / 2 - 10px);
                }
            }

            .textarea {
                margin-bottom: 10px;

                .input__wrap {
                    height: calc(100% - 22px);
                }

                textarea {
                    height: 100%;
                }
            }
        }
    }

    .addresses {
        display: flex;

        .content-wrapper {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            @include breakpoint('md') {
                flex-direction: row;
            }
        }

        &__map,
        &__info {
            @include breakpoint('md') {
                flex: 0 0 calc(50% - 15px);
            }
        }

        &__map {
            height: 400px;
            margin-bottom: 10px;

            @include breakpoint('sm') {
                margin-bottom: 20px;
            }

            @include breakpoint('md') {
                margin: 0 30px 0 0;
            }

            @include breakpoint('lg') {
                margin-right: 80px;
            }

            @include breakpoint('xl') {
                margin-right: 100px;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            font-size: 20px;

            @include breakpoint('md') {
                align-self: center;
            }

            .heading {
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;

                @include breakpoint('md') {
                    margin-bottom: 10px;
                }

                .text {
                    flex: 0 0 100%;
                }

                .curve {
                    display: none;
                    margin-top: -4px;

                    @include breakpoint('md') {
                        display: block;
                    }

                    @include breakpoint('lg') {
                        margin-top: -20px;
                    }
                }
            }
        }

        &__markers {
            margin: -5px;
            display: flex;
            flex-wrap: wrap;
        }

        &__marker {
            flex: 0 0 calc(100% - 10px);
            margin: 5px;
            display: flex;
            align-items: center;

            @include breakpoint('sm') {
                flex: 0 0 calc(50% - 10px);
            }

            .icon {
                flex-shrink: 0;
                width: 25px;
                height: 25px;
                margin-right: 4px;
                fill: none;
                --fill-color: #eb154c;
            }
        }
    }
}
